




































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import {
  ProfileUpdatePayload,
  Profile,
  GroupWithEmailDomains
} from '@/store/modules/profile/profile.state';
import {
  ValidationProvider,
  ValidationObserver,
  ValidationObserverInstance
} from 'vee-validate';
import {
  BInputWithValidation,
  BSelectWithValdation,
  EditProfileImageButton
} from './components';
import { AxiosError } from 'axios';
import _get from 'lodash/get';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { validateEmailDomain } from '@/jbi-shared/util/validate-email-domains.util';
import EmailChangeConfirmationModal from '@/views/Profile/components/EmailChangeConfirmationModal.vue';
import {
  AddressCountry,
  AddressFilterOption
} from '@/store/modules/admin/types/group-level-attribute.types';
import store, { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import UserAttributeForm from './components/UserAttributeForm.vue';
import {
  BulkUpdateMemberGroupUserAttributeValuePayload,
  MemberGroupUserAttributeValue
} from '@/store/modules/admin/types/group-user-attribute.types';
import { image } from 'html2canvas/dist/types/css/types/image';
import { profile } from 'console';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BInputWithValidation,
    BSelectWithValdation,
    BaseLoading,
    EditProfileImageButton,
    EmailChangeConfirmationModal,
    UserAttributeForm
  }
})
export default class ProfileDetail extends Vue {
  public isEditing: boolean = false;
  isSubmitButtonDisabled: boolean = false;
  countryErrorMsg: string = '';
  isAttributeFormInvalid: boolean = false;
  isCountryFieldDirty: boolean = false;
  updatedAttributeValueData: MemberGroupUserAttributeValue[] = [];
  resetFormKey: number = Math.floor(Math.random() * 999);

  $refs!: {
    vueavatar: HTMLCanvasElement & {
      clicked: () => void;
    };
    [key: string]: Vue | Element | Vue[] | Element[];
  };
  @Action('updateMe', {
    namespace: 'profile'
  })
  public updateMe!: (payload: ProfileUpdatePayload) => void;

  @Action('admin/updateCurrentUserAttributeValues')
  updateCurrentUserAttributeValues!: (
    payload: BulkUpdateMemberGroupUserAttributeValuePayload
  ) => void;

  @Action('userAttribute/updateAttributeValueValidity')
  updateAttributeValueValidity!: (payload: boolean) => void;

  @Action('getMe', {
    namespace: 'profile'
  })
  public getMe!: () => Promise<void>;

  @Action('getGroupList', {
    namespace: 'profile'
  })
  public getGroupList!: () => Promise<void>;

  @State('profile', {
    namespace: 'profile'
  })
  public profile!: Profile;

  @State('groupList', {
    namespace: 'profile'
  })
  public groupList!: GroupWithEmailDomains[];

  // TODO rename to profileApiState
  @State((state) => state.profile.apiState)
  public apiState: any;

  @Action('admin/getCountries')
  getAllCountries!: (addressFilter?: AddressFilterOption) => void;

  @State(({ admin }: RootState) => admin.apiState.getCountries)
  getAllCountriesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.countries)
  allCountries!: AddressCountry[] | undefined;

  @Getter('userAttribute/isMissingAttributeValue')
  isMissingAttributeValue!: boolean;

  @State(
    (state: RootState) => state.admin.apiState.updateCurrentUserAttributeValues
  )
  updateMemberGroupUserAttributeValues!: ApiState;

  public form: ProfileUpdatePayload = {
    firstName: undefined,
    lastName: undefined,
    gender: undefined,
    phoneNumber: undefined,
    organisation: undefined,
    country: undefined,
    specialty: undefined,
    researchgate: undefined,
    shortbio: undefined,
    source: undefined,
    email: undefined,
    profileImageUrl: undefined
  };
  showModalConfirmation = false;

  // username is view-only, hence, not part of the form input
  clonedUsername: string = '';

  get profileLoading() {
    return this.apiState.getMe.loading;
  }

  get countriesList(): AddressCountry[] {
    if (this.allCountries && this.form.country) {
      return this.allCountries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes((this.form.country as string).toLowerCase());
      });
    }

    if (!this.form.country) {
      return this.allCountries as AddressCountry[];
    }

    return [];
  }

  get genders() {
    return {
      male: 'Male',
      female: 'Female'
    };
  }

  get isProfileByAdminUser(): string {
    return this.$route.params.isAdminUser;
  }

  get getProfileImage() {
    return !!this.profile?.profileImageUrl ? true : false;
  }

  get policyUrl(): string {
    return `https://www.adelaide.edu.au/policies/62/?dsn=policy.document;field=data;id=87;m=view`;
  }

  public get initials(): string {
    if (this.form.firstName && this.form.lastName) {
      return (
        this.form.firstName!.charAt(0).toUpperCase() +
        this.form.lastName!.charAt(0).toUpperCase()
      );
    }
    return 'User';
  }

  public async mounted() {
    await this.getMe();
    await this.getGroupList();

    this.clonedUsername = this.profile.username || '';

    this.form = {
      ...this.form,
      ...this.profile
    };

    if (this.allCountries === undefined) {
      this.getAllCountries();
    }

    if (this.isMissingAttributeValue) {
      // Get the distance between current client view and the target element.
      const elementPosition: number = document
        .getElementsByClassName('is-danger')[0]
        .getBoundingClientRect().top;
      // Calculate the scroll position.
      const scrollPosition: number = window.pageYOffset + elementPosition;
      window.scrollTo({
        // To add some extra offset space to the scroll top of target element
        // if without extra spaces, it will scroll to the exact top of the element.
        top: scrollPosition - window.screen.height / 5,
        // Due to Firefox are having issues with smooth scrolling, so
        // will apply normal scroll specifically for Firefox.
        behavior: navigator.userAgent.match(/firefox|fxios/i)
          ? 'auto'
          : 'smooth'
      });
      this.$buefy.dialog.alert({
        message: `
          <p class="buefy-dialog-title">Some mandatory information are missing</p>
          <p class="buefy-dialog-content">
            You need to provide information for the mandatory fields in your profile
            (which are highlighted in red) before being allowed to log in.
          </p>
        `,
        confirmText: 'OK'
      });
    }
  }

  public async updatedUser() {
    return await this.getMe();
  }

  public handleUpdate() {
    // Email has changed, check for domains
    if (this.form.email !== this.profile.email) {
      const inValidEmailGroupList: GroupWithEmailDomains[] = [];
      this.groupList.map((groupWithEmailDomain: GroupWithEmailDomains) => {
        /**
         * validate if email is valid for each group or not.
         * i.e. if user is changed from firstUser@snappymob.com to secondUser@snappymob.com
         * In that case, there is no need to show confirmation modal, since domain is still snappymob.com
         */
        if (
          this.form.email &&
          groupWithEmailDomain.emailDomainArray.length > 0 &&
          !validateEmailDomain(
            this.form.email,
            groupWithEmailDomain.emailDomainArray
          )
        ) {
          this.showModalConfirmation = true;
          inValidEmailGroupList.push(groupWithEmailDomain);
        }
      });

      if (this.showModalConfirmation) {
        this.$buefy.modal.open({
          parent: this,
          component: EmailChangeConfirmationModal,
          hasModalCard: true,
          trapFocus: true,
          width: 550,
          props: {
            newEmail: this.form.email,
            inValidEmailGroupList
          },
          events: {
            submit: () => {
              return this.updateForms();
            }
          }
        });
      } else {
        return this.updateForms();
      }
    } else {
      return this.updateForms();
    }
  }

  updateForms(): void {
    this.updateMe(this.form);
    this.updateCurrentUserAttributeValues({
      memberGroupUserAttributeValues: this.updatedAttributeValueData
    });
  }

  assignCountryValue(selectedCountry: AddressCountry | null): void {
    this.isCountryFieldDirty = true;
    this.form.country = selectedCountry ? selectedCountry.name : '';
  }

  verifyCountryInput(): void {
    if (!this.allCountries) {
      this.countryErrorMsg = '';
      return;
    }

    if (this.form.country) {
      const targetCountry: AddressCountry | undefined = this.allCountries.find(
        (country) =>
          country.name.toLowerCase() ===
          (this.form.country as string).toLowerCase()
      );

      if (!targetCountry) {
        this.countryErrorMsg = 'Invalid country name.';
        return;
      }

      this.form.country = targetCountry.name;
      this.countryErrorMsg = '';
    }
  }

  updateUserAttributeFormValidity(isInvalid: boolean): void {
    this.isAttributeFormInvalid = isInvalid;
  }

  updateUserAttributeFormValues(
    updatedAttributeValueData: MemberGroupUserAttributeValue[]
  ): void {
    this.updatedAttributeValueData = updatedAttributeValueData;
  }

  resetForm(): void {
    this.resetFormKey += 1;
    this.isEditing = false;
    this.isSubmitButtonDisabled = false;
    this.countryErrorMsg = '';
    this.isAttributeFormInvalid = false;
    this.isCountryFieldDirty = false;
    this.updatedAttributeValueData = [];
    (this.$refs.observer as ValidationObserverInstance).reset();
  }

  @Watch('apiState.updateMe.error')
  public watchUpdateMeError(error: AxiosError) {
    if (!error) {
      return;
    }
    this.$buefy.toast.open({
      // TODO: handle error
      message: _get(error, 'response.data.message', 'Unknown Error'),
      type: 'is-danger',
      position: 'is-top'
    });
  }

  @Watch('apiState.updateMe.success')
  public watchUpdateMeSuccess(success: boolean) {
    if (!success) {
      return;
    }
    this.isEditing = false;
    this.$buefy.toast.open({
      message: 'Profile updated successfully.',
      type: 'is-success',
      position: 'is-top'
    });
    this.resetForm();
    window.scrollTo(0, 0);
  }

  @Watch('updateMemberGroupUserAttributeValues.error')
  public onUserAttributeUpdateError(): void {
    this.$buefy.toast.open({
      message: 'Failed to update user attributes.',
      type: 'is-danger',
      position: 'is-top'
    });
  }

  @Watch('updateMemberGroupUserAttributeValues.success')
  public async onUserAttributeUpdateSuccess(): Promise<void> {
    // To avoid removeDestinationAppUrl API call get cancelled due to
    // the destination app redirection, so await is used.
    // With .dispatch() the Vuex action can be executed immediately
    // without declaring another @Action variable under the component class

    await store.dispatch('profile/removeDestinationAppUrl');
    const destinationAppUrl: string =
      store.getters['profile/destinationAppUrl'];
    this.updateAttributeValueValidity(false);
    if (destinationAppUrl && destinationAppUrl !== '') {
      window.location.href = destinationAppUrl;
    } else {
      this.$router.push({ name: 'UserProfile' });
    }
  }

  @Watch('isEditing')
  public watchEditingForm(value: boolean, prevValue: boolean) {
    if (value === true && prevValue === false) {
      this.form.firstName = this.profile.firstName;

      this.form.lastName = this.profile.lastName;
      this.form.gender = this.profile.gender;
      this.form.phoneNumber = this.profile.phoneNumber;
      this.form.organisation = this.profile.organisation;
      this.form.country = this.profile.country;
      this.form.researchgate = this.profile.researchgate;
      this.form.shortbio = this.profile.shortbio;
      this.form.source = this.profile.source;
      this.form.email = this.profile.email;
      this.form.profileImageUrl = this.profile.profileImageUrl;
    }
  }

  @Watch('form.firstName')
  @Watch('form.lastName')
  @Watch('form.email')
  @Watch('countryErrorMsg')
  public watchFormValues() {
    this.isSubmitButtonDisabled = !(
      !this.countryErrorMsg &&
      this.form.firstName &&
      this.form.lastName &&
      this.form.email
    );
  }
}
